import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { AthleteProfilePayload, ClubProfilePayload, ConnectionApiResponse, FanProfilePayload, OtherUsersPayload, Player, ScoutProfilePayload, SettingsPayload, UnconnectedUsersPayload } from "./types";


const baseUrl = process.env.REACT_APP_BASE_URL;

interface LoginResponse {
  token: string;

  data: {
    fullname: string;
     firstName:string;
    email: string;
    user: {
      profileType: string;
      email: string;
      fullname: string;
      firstName:string;
    };

}
  // Add other properties of the login response here as needed
}



interface RegisterPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  country: string;
}
interface verifyPayload {
  email: string;
  otp?: string;
}
interface confirmPasswordPayload {
  newPassword: string;
  confirmPassword: string;
  token:string;
}

interface updateProfilePayload {
  email: string;
  profileType: string;
  sportType: string;
  teamName: string;
}

// Define the vendor info structure from localStorage
// interface UserTokenInfo {
//   token: string;
//   // Add other properties if needed
// }

const apiClient = axios.create({
  baseURL: baseUrl,
  timeout: 120000,
});

apiClient.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    const userToken = localStorage.getItem("authToken");

    if (userToken && config.headers) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiClient.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  if (config.data instanceof FormData && config.headers) {
    config.headers["Content-Type"] = "multipart/form-data";
  }
  return config;
});

const registerUser = (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  country: string
): Promise<AxiosResponse<LoginResponse>> => {
  const payload: RegisterPayload = { firstName, lastName, email, password, country };
  return apiClient.post<LoginResponse>("/auth/register", payload);
};

const verifyOtp = (email: string, otp: string): Promise<AxiosResponse<LoginResponse>> => {
  const payload: verifyPayload = { email, otp };
  return apiClient.post<LoginResponse>("/auth/validateOTP", payload);
};
const resendOtp = (): Promise<AxiosResponse<LoginResponse>> => {
  return apiClient.post<LoginResponse>("/auth/resend-otp");
};

const registrationType = (
  email: string,
  profileType: string,
  sportType: string,
  teamName: string
): Promise<AxiosResponse<LoginResponse>> => {
  const payload: updateProfilePayload = { email, profileType, sportType, teamName };
  return apiClient.post<LoginResponse>("/auth/updateProfileType", payload);
};

const forgotPassword = (email: string): Promise<AxiosResponse<LoginResponse>> => {
  const payload: verifyPayload = { email };
  return apiClient.post<LoginResponse>("/auth/forgot-password", payload);
};
const resetPassword = (currentPassword: string): Promise<AxiosResponse<SettingsPayload>> => {
  const payload = { currentPassword };
  return apiClient.post<SettingsPayload>("/auth/reset-password", payload);
};
const cofirmForgetPassword = (newPassword: string, confirmPassword:string, token:string): Promise<AxiosResponse<LoginResponse>> => {
  const payload: confirmPasswordPayload = { newPassword, confirmPassword, token };
  return apiClient.post<LoginResponse>("/auth/confirm-forgot-password", payload);
};





// ===================PROFILE UPGRADE =================================================================================================================================


const upgradeAtheleteProfile = (
address:string,
dateOfBirth:string,
yearsOfExperience:number,
height:string,
weight:string,
biography:string,
currentClub:string,
preferredPosition:string,
preferredFoot:string,
preferredClub:string,
profession:string,
  profilePictureUrl:string,
  profileReelUrl:string,
): Promise<AxiosResponse<AthleteProfilePayload>> => {
  const payload: AthleteProfilePayload = {   address,
  dateOfBirth,
  yearsOfExperience,
  height,
  weight,
  biography,
  currentClub,
  preferredPosition,
  preferredFoot,
  preferredClub,
  profession, asset:{profilePictureUrl, profileReelUrl } };
  return apiClient.post<AthleteProfilePayload>(`/api/users/create-profile`, payload);
};




const upgradeScoutProfile = (
  email:string,
city:string,
scoutingExperienceYears:number,
areasOfSpecialization:string[],
affiliatedOrganizations:string[],
scoutingRegion:string[],
certifications:string[],
preferredAttributes:string,
regionsOfInterest:string[],
sports:string[],
notesOnAthletes:string,
  position:string,
  ageGroup:string,
  scoutingHistory:string,
  phoneNumber:string,
  socialMediaLinks:string,
  profilePictureUrl:string,
  profileReelUrl:string,
): Promise<AxiosResponse<ScoutProfilePayload>> => {
  const payload: ScoutProfilePayload = {  
    username:"johnDoeScout",
    email,
     city,
  scoutingExperienceYears,
  notableTalents:[    "Athlete A",
    "Athlete B",
    "Athlete C"
],
  areasOfSpecialization,
  affiliatedOrganizations,
  scoutingRegion,
  certifications,
  preferredAttributes,
  regionsOfInterest,
  sports,
  notesOnAthletes,
  position,
  ageGroup,
  scoutingHistory,
  phoneNumber,
  socialMediaLinks,
  isActive:true,
 asset:{profilePictureUrl, profileReelUrl } };
  return apiClient.post<ScoutProfilePayload>(`/api/users/create-profile`, payload);
};




const upgradeFanProfile = (
country:string,
city:string,
favoriteSports:string[],
notificationPreferences:string[],
interactions:string[],
  profilePictureUrl:string,
): Promise<AxiosResponse<FanProfilePayload>> => {
  const payload: FanProfilePayload = {  
    username:"",
     country,
     city,
favoriteSports,
favoriteAthletes:[""],
notificationPreferences,
interactions,
purchasedItems:[""],
 asset:{profilePictureUrl, profileReelUrl :""} };
  return apiClient.post<FanProfilePayload>(`/api/users/create-profile`, payload);
};



const upgradeClubProfile = (
  clubName: string,
  country: string,
  city: string,
  competitionLevel: string,
  contactEmail: string,
  contactPersonName: string,
  contactPhone: string,
  website: string,
  socialLinks: string[],
  recruitmentAreas: string,
  playerType: string,
  teamLogoUrl: string,
  players: Player[],
  clubAchievements: string[],
  clubVacancies: string[],
    profilePictureUrl: string,

): Promise<AxiosResponse<ClubProfilePayload>> => {
  
  const payload: ClubProfilePayload = {  
    clubName,
    country,
    city,
    competitionLevel,
    contactEmail,
    contactPersonName,
    contactPhone,
    website,
    socialLinks,
    recruitmentAreas,
    playerType,
    teamLogoUrl,
    players,
    clubAchievements,
    clubVacancies,
    asset:{
        profilePictureUrl,
  profileReelUrl:""
    }
  };

  return apiClient.post<ClubProfilePayload>(`/api/users/create-profile`, payload);
};





// ===================FOLLOW & CONNECT =================================================================================================================================

const getUnConnectedUsers = () : Promise<AxiosResponse<UnconnectedUsersPayload>> =>{
  return apiClient.get<UnconnectedUsersPayload>("api/connections/unconnected-users")
}


const getRecievedConnectedUsers = () : Promise<AxiosResponse<UnconnectedUsersPayload>> =>{
  return apiClient.get<UnconnectedUsersPayload>("api/connections/received")
}
const getTopClubs = () : Promise<AxiosResponse<UnconnectedUsersPayload>> =>{
  return apiClient.get<UnconnectedUsersPayload>("api/clubs/top/clubs")
}
const getTopAgents = () : Promise<AxiosResponse<UnconnectedUsersPayload>> =>{
  return apiClient.get<UnconnectedUsersPayload>("api/clubs/top/agents")
}
const getTopAtheletes = () : Promise<AxiosResponse<UnconnectedUsersPayload>> =>{
  return apiClient.get<UnconnectedUsersPayload>("api/clubs/top-athletes")
}
const getPersonalNetwork = () : Promise<AxiosResponse<UnconnectedUsersPayload>> =>{
  return apiClient.get<UnconnectedUsersPayload>("api/connections/networks")
}


const createConnection = (
  email: string
): Promise<AxiosResponse<ConnectionApiResponse>> => {
  const payload = {  
    email, 
  };
  return apiClient.post<ConnectionApiResponse>(`api/connections/send`, payload);
};
const acceptConnection = (
  email: string
): Promise<AxiosResponse<ConnectionApiResponse>> => {
  const payload = {  
    email, 
  };
  return apiClient.post<ConnectionApiResponse>(`api/connections/accept`, payload);
};
const toggleFollow = (
  email: string
): Promise<AxiosResponse<ConnectionApiResponse>> => {
  const payload = {  
    email, 
  };
  return apiClient.post<ConnectionApiResponse>(`/api/follows/toggle?followerEmail=${email}`, payload);
};


// ===================SETTINGS=================================================================================================================================
const toggle2faAuth = (
  has2FA: boolean
): Promise<AxiosResponse<ConnectionApiResponse>> => {
  const payload = {  
    has2FA, 
  };
  return apiClient.patch<ConnectionApiResponse>(`/api/settings`, payload);
};



// ===================PROFILE=================================================================================================================================

const getOtherUserProfile = (email:string) : Promise<AxiosResponse<OtherUsersPayload>> =>{
  return apiClient.get<OtherUsersPayload>(`api/users/user-details?email=${email}`)
}
// const searchUsers = (email:any) : Promise<AxiosResponse<OtherUsersPayload>> =>{
//   return apiClient.get<OtherUsersPayload>(`api/users/user-details?email=${email}`)
// }
const getSettings = () : Promise<AxiosResponse<SettingsPayload>> =>{
  return apiClient.get<SettingsPayload>(`api/settings`)
}


export { registerUser, verifyOtp, registrationType,upgradeAtheleteProfile , upgradeScoutProfile, upgradeFanProfile , upgradeClubProfile, getUnConnectedUsers, createConnection, getRecievedConnectedUsers, toggleFollow, toggle2faAuth, forgotPassword, cofirmForgetPassword, getTopClubs, getTopAgents, getTopAtheletes, acceptConnection, getPersonalNetwork, getOtherUserProfile, resendOtp, getSettings, resetPassword};

import React, { useEffect } from "react";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import {
  Avatar,
  Box,
  Button,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import EmailIcon from "assets/icons/email.svg";
import { IoEllipsisVertical } from "react-icons/io5";
import { networkContent } from "helpers/dummydata";
import useAuthDetails from "pages/auth/useAuthDetails";
import { useGetApi } from "api/hooks/useGetApi";
import { IoPersonRemoveOutline } from "react-icons/io5";
import { getPersonalNetwork } from "api";
import { convertToTitleCase } from "./GlobalNetwork";

type NavViewType =
  | "All"
  | "Athletes"
  | "Scout"
  | "Club/Organization"
  | "Followers"
  | "Following";

export default function PersonalNetwork() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const { data: myNetworks, loading } = useGetApi(getPersonalNetwork);

  const [filteredContent, setFilteredContent] = React.useState(
    myNetworks?.data
  );

  useEffect(() => {
    setFilteredContent(myNetworks?.data);
  }, [myNetworks]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { profileType } = useAuthDetails();
  const [navView, setNavView] = React.useState<NavViewType>(
    profileType === "TEAM" ? "Followers" : "All"
  );

  // Filter content based on the selected navView
  useEffect(() => {
    if (navView === "All") {
      setFilteredContent(myNetworks?.data); // Show all data if "All" is selected
    } else {
      setFilteredContent(
        myNetworks?.data.filter((item) => convertToTitleCase(item.profileType) === navView)
      );
    }
  }, [navView]);

  // Function to get the count of items for each NavView
  const getViewCount = (view: NavViewType) => {
    if (view === "All") {
      return filteredContent?.length;
    }
    return filteredContent?.filter((item) => item.profileType === view).length;
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Stack
        direction="row"
        spacing={4}
        marginBottom={{ lg: 3 }}
        paddingX={4}
        borderRadius="16px"
        overflow="auto"
        className="bg-white"
      >
        {profileType === "TEAM" ? (
          <>
            {NavViewsTeam.map((view) => (
              <Stack
                key={view?.id}
                direction="row"
                alignItems="center"
                paddingY={{ xs: 1, md: 2.5 }}
                spacing={{ xs: 1, md: 1.5 }}
                onClick={() => {
                  setNavView(view?.name);
                }}
                className="cursor-pointer"
              >
                <Typography
                  variant="p$14"
                  color={navView === view.name ? "primary" : "text"}
                  fontWeight={navView === view.name ? "600" : "medium"}
                >
                  {view?.name}
                </Typography>
                <Typography
                  fontWeight="medium"
                  borderRadius="8px"
                  component="span"
                  paddingX={1}
                  className={`${
                    navView === view.name
                      ? " bg-primary text-white"
                      : " bg-[#F3F6FC] text-[#AAB1C0]"
                  } !text-[12px]`}
                >
                  {getViewCount(view.name)}
                </Typography>
              </Stack>
            ))}
          </>
        ) : (
          <>
            {NavViews.map((view) => (
              <Stack
                key={view?.id}
                direction="row"
                alignItems="center"
                paddingY={{ xs: 1, md: 2.5 }}
                spacing={{ xs: 1, md: 1.5 }}
                onClick={() => {
                  setNavView(view?.name);
                }}
                className="cursor-pointer"
              >
                <Typography
                  variant="p$14"
                  color={navView === view.name ? "primary" : "text"}
                  fontWeight={navView === view.name ? "600" : "medium"}
                >
                  {view?.name}
                </Typography>
                <Typography
                  fontWeight="medium"
                  borderRadius="8px"
                  component="span"
                  paddingX={1}
                  className={`${
                    navView === view.name
                      ? " bg-primary text-white"
                      : " bg-[#F3F6FC] text-[#AAB1C0]"
                  } !text-[12px]`}
                >
                  {getViewCount(view.name)}
                </Typography>
              </Stack>
            ))}
          </>
        )}
      </Stack>
      <Box
        paddingY={{ xs: 2, lg: 4 }}
        paddingX={{ xs: 2, md: 4 }}
        borderRadius="16px"
        width="100%"
        className="bg-white"
      >
        {(filteredContent?.length ?? 0) > 0 && (
          <TextField
            placeholder="Search athletes, scout, fanbase or teams"
            fullWidth
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 1.5 }}>
                    <SearchIcon />
                  </InputAdornment>
                ),
              },
            }}
            sx={{
              marginBottom: { xs: 2, md: 5 },
              "& fieldset": {
                borderColor: theme.palette.primary.light,
                boxShadow: `1px 1px 2px ${theme.palette.primary.light}`,
              },
              "&:hover fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary.main,
              },
            }}
          />
        )}

        <Stack
          spacing={{ xs: 2, md: 3 }}
          divider={<hr className="border-[#F1F1F1]" />}
        >
          {(filteredContent?.length ?? 0) > 0 ? (
            filteredContent?.map((item, idx) => (
              <Stack key={idx} direction="row" justifyContent="space-between">
                <Stack
                  direction="row"
                  spacing={{ xs: 1, md: 3 }}
                  alignItems="center"
                >
                  <Avatar
                    src={item?.profilePictureUrl}
                    alt="avatar"
                    sx={{
                      width: { xs: 40, md: 60 },
                      height: { xs: 40, md: 60 },
                    }}
                  />
                  <div>
                    <p className="text-base">{item?.firstName}</p>
                    <p className="text-[#8D95A0] text-xs">
                      {convertToTitleCase(item?.profileType)} -{" "}
                      {item?.sportType}
                    </p>
                  </div>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ xs: 1, md: 1.5 }}
                >
                  <Button variant="outlined" sx={{ fontWeight: 400, fontSize:'12px' }}>
                    <IoPersonRemoveOutline className="mr-2 text-[12px]"  />
                   Remove from my network
                  </Button>
                  {/* <button onClick={handleClick}>
                    <IoEllipsisVertical className="text-lg text-[#6A7280]" />
                  </button> */}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      width: 220,
                      mt: 2,
                      border: 0,
                      borderRadius: 1,
                      boxShadow: "none",
                      "& .MuiPopover-paper": {
                        width: 220,
                        bgcolor: "#EAF1FD",
                        color: "#1D69D8",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        boxShadow: "none",
                      },
                    }}
                  >
                    <button>Remove from my network</button>
                  </Popover>
                </Stack>
              </Stack>
            ))
          ) : (
            <div className="w-full flex justify-center mt-2 text-primary font-medium">
              <p>No {navView} found</p>
            </div>
          )}
        </Stack>
        {(filteredContent?.length ?? 0) > 0 && (
          <Stack
            justifyItems="center"
            alignItems="center"
            marginTop={4}
            position="relative"
            spacing={4}
          >
            <hr className="absolute w-screen -ml-12 border-[#F1F1F1]" />
            <Typography variant="p$16" color="primary" fontWeight="medium">
              Show more results
            </Typography>
          </Stack>
        )}
      </Box>
    </>
  );
}

const NavViews: { id: number; name: NavViewType }[] = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Athletes",
  },
  {
    id: 3,
    name: "Scout",
  },
  {
    id: 4,
    name: "Club/Organization",
  },
];
const NavViewsTeam: { id: number; name: NavViewType }[] = [
  {
    id: 1,
    name: "Followers",
  },
  {
    id: 2,
    name: "Following",
  },
];

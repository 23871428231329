export const PATHS = {
  LOGIN: "/login",
  LOGIN_OTP: "/login-2fa",
  SIGNUP: "/auth/registration",
  FORGOT_PASSWORD: "/auth/forgot-password",
  SET_PASSWORD : "/auth/confirm-new-password",
  VERIFY_OTP: "/auth/verify-otp",
  ACCOUNT_SUCCESSFUL: "/auth/account-created",
  LIVESCORE: "/livescore",
  REGISTRATION_TYPE: "/auth/registration-type",
  FEED: "/",
  NETWORK: "/network",
  OTHER_USER_PROFILE: "/via",
  SETTINGS: {
    PROFILE: "/settings/profile",
    PASSWORD_RESET: "/settings/reset-password",
    NOTIFICATIONS: "/settings/notifications",
    ACTIVITIES: "/settings/activities",
  },
  MESSAGES: "/messages",
  NOTIFICATIONS: "/notifications",
  PROFILE: "/profile",
  GET_STARTED: "/get-started",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_OF_USE: "/term-of-use",
  COOKIE_POLICY: "/cookie-policy",
};
